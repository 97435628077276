<template>
  <div class="virtual">
    <h1 class="title">Virtual Screening</h1>
    <div class="sections">
      <section class="section1">
        <div class="step1">
          <div class="step_tip">
            <p>Select a Target:</p>
          </div>
          <div class="input">
            <div class="model-input">
              <el-select v-model="selectedModel" filterable placeholder="Select Model" size="large"
                class="model-select">
                <el-option v-for="item in modelList" :key="item.name" :label="item.label" :value="item.name_use_on_web"
                  @click="onSelectModel(item.category, item.name)">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="select_data">
            <div class="mode" v-for="(models, mode, idx) in model" :key="mode">
              <div class="mode-bar">
                <div class="mode-name">{{ mode }}</div>
                <div class="show-all-btn" @click="onClickShowAll(idx)">
                  <p v-if="!isShowAll[idx]">view all</p>
                  <p v-else>Fold</p>
                </div>
              </div>
              <div :class="['models', isShowAll[idx] ? 'show-all' : '']">
                <div class="model-item" v-for="model in models" :key="model.name"
                  @click="onSelectModel(model.category, model.name)">
                  <img class="model-icon" src="../assets/model_icon.svg" alt="" />
                  <a :title="model.name_use_on_web">
                    <p>{{ model.name_use_on_web }}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section2">
        <div class="step2">
          <div class="step_tip">
            <p>Upload a Dataset:</p>
          </div>
          <div class="user-dataset">
            <div class="upload-dataset">
              <button id="upload-btn">
                Upload CSV File
                <input type="file" id="input-file" accept=".csv" @change="onUploadFile" />
              </button>
              <p class="file-name">{{ fileName }}</p>
            </div>
          </div>
          <div class="download-example">
            <a @click="downloadExample">
              <el-icon>
                <bottom />
              </el-icon> Example
            </a>
          </div>
        </div>
      </section>
    </div>
    <div class="step3">
      <div class="submit-line">
        <img src="../assets/singleprediction/photo1.png" alt="" />
      </div>
      <button @click="onSubmit" id="submit-btn">Submit</button>
    </div>
    <virtual-popper v-show="showPopper" :infos="popperInfo" @onSubmit="onSubmitPopper" @onCancel="onCancelPopper">
    </virtual-popper>
    <!-- // <Loading v-show="isLoading"></Loading> -->
  </div>
</template>

<script>
import modelData from "../api/data/ishiner_dataset.json";
// import offerDatasets from "../api/data/offer_dataset.json";
import { virtualApi } from "../api/index";
// import loadingfrom "../components/loading.vue";

import popper from "../components/popper/predict.vue";
export default {
  data() {
    return {
      selectedMode: "",
      selectedModel: "",
      modelList: [],
      model: {
        "Cancer Cell Lines": [],
        "Anti-cancer Targets": [],
        "NCI60 Cancer Cells": [],
      },
      isShowAll: [0, 0, 0, 0, 0, 0],
      fileName: "no file",
      datasetFile: null,
      taskName: "",
      email: "",
      showPopper: false,
      popperInfo: {},
      // isLoading: false,
    };
  },
  components: {
    "virtual-popper": popper,
    // Loading: loading,
  },
  mounted() {
    // init model data
    for (let i = 0; i < modelData.length; i++) {
      if (
        modelData[i].category_use_on_web == "Cancer Cell Lines" ||
        modelData[i].category_use_on_web == "Anti-cancer Targets" ||
        modelData[i].category_use_on_web == "NCI60 Cancer Cells"
      ) {
        this.modelList.push(modelData[i]);
        this.model[modelData[i].category_use_on_web].push(modelData[i]);
      }
    }
  },
  methods: {
    onClickShowAll(idx) {
      this.isShowAll[idx] = !this.isShowAll[idx];
    },
    onSelectModel(mode, modelName) {
      this.selectedMode = mode;
      if (this.selectedMode == "nci60_cancer_cell") {
        this.selectedModel = modelName.slice(6);
      } else {
        this.selectedModel = modelName;
      }
      console.log(this.selectedMode, this.selectedModel);
    },
    onRadioChange() {
      this.isUserDataset = !this.isUserDataset;
      this.isOfferDataset = !this.isOfferDataset;
    },
    onUploadFile(e) {
      this.datasetFile = e.target.files[0];
      console.log(typeof this.datasetFile);
      this.fileName = this.datasetFile.name;
    },
    onSubmitPopper(taskName, email) {
      this.taskName = taskName;
      this.email = email;
      this.startSelect();
      this.showPopper = false;
    },
    onCancelPopper() {
      this.showPopper = false;
      console.log("oncancel");
    },
    onSubmit() {
      if (this.checkForm()) {
        this.popperInfo = {
          Model: this.selectedModel,
          Dataset: this.fileName,
        };
        this.showPopper = true;
      }
    },
    checkForm() {
      if (this.selectedModel == "") {
        this.$store.commit("alertMsg", {
          msg: "No model is selected!",
          type: "error",
        });
        return false;
      }
      if (this.fileName == "no file") {
        this.$store.commit("alertMsg", {
          msg: "Please upload a dataset.",
          type: "error",
        });
        return false;
      }
      return true;
    },
    startSelect() {
      this.fetchWithFile();
    },
    fetchWithFile() {
      this.$store.commit("startLoading");

      const formData = new FormData();
      formData.append("file", this.datasetFile);
      formData.append("title", this.taskName);
      formData.append("model_name", this.selectedModel);
      formData.append("email", this.email);
      virtualApi
        .selectWithFile(this.selectedMode, formData)
        .then(this.handleFetchResult)
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        })
        .finally(() => {
          this.$store.commit("endLoading");
        });
    },
    handleFetchResult(res) {
      console.log(res);
      if (res.code == 200) {
        this.$store.commit("alertMsg", {
          msg: `Submit sucessfully. The result will sent to ${this.email} when finished.`,
          type: "ok",
        });
      } else {
        this.$store.commit("alertMsg", {
          msg: res.msg,
          type: "error",
        });
      }
    },
    downloadExample() {
      window.open(
        this.$store.state.downloadUrl + "/media/files/Batch_Example.csv"
      );
    },
  },
  computed: {
    // filterModelList() {
    //   if (!this.selectedModel || this.selectedModel == "")
    //     return this.modelList;
    //   else {
    //     let pattern = this.selectedModel.trim().toLowerCase();
    //     return this.modelList.filter((item) => {
    //       item.match(pattern);
    //     });
    //   }
    // },
  },
};
</script>

<style scoped>
.virtual {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  color: white;
}

.title {
  font-size: 30pt;
  margin: 20px 0;
  padding: 0 10px;
  text-align: center;
}

.sections {
  display: flex;
}

.input {
  width: fit-content;
  /* background: red; */
  display: flex;
  align-items: center;
}

.input_tip {
  margin: 5px 0;
}

#step1_input {
  box-sizing: border-box;
  width: 300px;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  margin: 5px;
}

.step_tip {
  display: flex;
  align-items: center;
  font-size: 20pt;
  font-weight: bold;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 50px;
  /* background: blue; */
}

.section1 {
  align-items: flex-end;
}

.section2 {
  align-items: flex-start;
}

.step2,
.step1 {
  /* background: pink; */
  /* height: fit-content; */
  width: fit-content;
  /* background: red; */
}

.mode {
  width: fit-content;
  /* background-color: var(--color2-dark);  */
  box-sizing: border-box;
  /* padding: 0 10px; */
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.mode-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* background: red; */
}

.mode-name {
  font-size: 18pt;
  /* font-weight: bold; */
  margin: 5px 0;
}

.show-all-btn {
  width: fit-content;
  align-self: end;
  padding: 0 10px;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
}

.show-all-btn:hover {
  color: var(--color2-dark);
}

.model-select {
  margin: 10px 0;
  font-size: 16pt;
  border-radius: 20px;
  width: 250px;
}

.models {
  width: fit-content;
  height: 40px;
  display: grid;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 10px 0;
  grid-template-columns: repeat(4, 180px);
  gap: 10px;
}

.models.show-all {
  /* overflow-y: scroll; */
  height: fit-content;
}

.model-item {
  box-sizing: border-box;
  height: 35px;
  background-color: var(--color2-dark);
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  color: var(--color1-dark);
  display: flex;
  align-items: center;
}

.model-icon {
  /* width: 10px; */
  padding: 0 4px;
  height: 20px;
  width: 20px;
}

.model-item a p {
  width: 140px;
  text-align: center;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.model-item:hover {
  color: white;
}

.upload-dataset {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

#upload-btn {
  position: relative;
  height: 40px;
  width: 300px;
  text-align: start;
  padding-left: 10px;
  color: grey;
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 14pt;
  /* width: 100px; */
}

.file-name {
  margin: 0 10px;
  font-size: 16px;
}

#input-file {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  bottom: 0;
  cursor: pointer;
}

.download-example {
  cursor: pointer;
  width: fit-content;
  /* background: red; */
}

.download-example:hover {
  color: var(--color2-dark);
}

.step3 {
  /* background: pink; */
  margin: 0 50px;
  text-align: center;
}

.submit-line img {
  width: 100%;
}

#submit-btn {
  /* height: 40px; */
  margin: 30px;
  border-radius: 15px;
  font-size: 20pt;
  padding: 6px 0;
  width: 350px;
  border: none;
  outline: none;
  cursor: pointer;
  background: linear-gradient(to right,
      var(--color2-dark),
      var(--color2-light));
}

#submit-btn:hover {
  background: linear-gradient(to left, var(--color2-dark), var(--color2-light));
}
</style>